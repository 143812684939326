<template>
  <div class="ap">
    <van-cell-group inset>
      <van-field
        label="预约人"
        :model-value="data.userName"
        readonly
        colon="true"
        class="cl_data_field"
      />
      <van-field
        label="电话"
        :model-value="data.userAccount"
        readonly
        colon="true"
        class="cl_data_field"
      />
      <van-field
        label="床号"
        :model-value="data.userArea"
        readonly
        colon="true"
        class="cl_data_field"
      />
      <van-field
        label="病案号"
        :model-value="data.userBed"
        readonly
        colon="true"
        class="cl_data_field"
      />
      <van-field
        label="预约时间"
        :model-value="data.appointVisitTime"
        readonly
        colon="true"
        class="cl_data_field"
      />
    </van-cell-group>
  </div>
</template>

<script>
import { details } from "@/api/appdetails/appdetails.js";
export default {
  name: "detailsComponent",
  //   props: {
  //     val: String,
  //   },
  data() {
    return {
      data: {},
      val: "",
    };
  },
  created() {
    this.val = this.$route.query.id;
    this.inset();
  },
  methods: {
    // 接收任务详情页的数据
    async inset() {
      const res = await details(this.val);
      this.data = res.data.data;
    },
  },
  mounted() {
    this.$emit("value", this.data.id);
  },
};
</script>

<style scoped>
.ap {
  width: 100%;
  padding-top: 2rem;
}

.cl_data_field {
  /* border-bottom: 1px solid black; */
  font-size: 1.7rem;
  font-weight: 400;
  height: 6rem;
}
</style>
