// import { Vue, createApp } from 'vue'
// import App from './App.vue'
// import router from './router/router.js'

// Vue.config.productionTip = false

// createApp(App).mount('#app')
// new Vue({
//   render: h => h(App),
//   router,
// }).$mount('#app')

import amfeFlexible from './postcss.config.js'
import { createApp } from 'vue'
import App from './App.vue'
import Router from './router/router.js'
import { Button } from 'vant';
import { Form, Field, CellGroup, Toast } from 'vant';
import { Tab, Tabs } from 'vant';
import { Icon } from 'vant';
import { NavBar } from 'vant';
import { Overlay } from 'vant';
import { Image as VanImage } from 'vant';
import { Search } from 'vant';
import { SwipeCell } from 'vant';
import { Popup } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Picker } from 'vant';
import { CountDown } from 'vant';
import { PullRefresh } from 'vant';
import { Divider } from 'vant';
import { Badge } from 'vant';

// import Swiper from 'swiper'

// createApp(App).mount('#app')

const app = createApp(App)
    // const app = createApp();
app.use(CountDown);
app.use(PullRefresh);
app.use(Form);
app.use(Field);
app.use(CellGroup);
app.use(Router)
app.use(Button);
app.use(amfeFlexible);
app.use(Tab);
app.use(Tabs);
app.use(Icon);
app.use(NavBar);
app.use(Overlay);
app.use(VanImage);
app.use(Search);
app.use(SwipeCell);
app.use(Popup);
app.use(Radio);
app.use(RadioGroup);
app.use(Picker);
app.use(Toast);
app.use(Divider);
app.use(Badge);
// app.use(Swiper)
// app.use(ElementPlus)
app.mount('#app')
