<template>
  <div class="ap">

    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
<!--      <p>待探视</p>-->
      <!--待探视的详细内容:s  -->
      <div class="cl_task" v-for="item in data" :key="item.appointId" @click="getvisit(item.appointId)">
        <div class="cl_visitime">
          <div class="cl_notin">
            <span><img src="../../../assets/tr6.png" alt="" style="width: 1.8rem;height: 1.8rem"></span><span>待探视</span>
          </div>
          <div class="cl_time">
            <van-count-down :time="item.unix" format="DD 天 HH 时 mm 分 ss 秒" />
          </div>
        </div>
        <van-cell-group inset class="cl_max_group">
          <van-field label="预约人" :model-value="item.userName" readonly class="cl_group" />
          <van-field label="电话" :model-value="item.userAccount" readonly class="cl_group" />
          <van-field label="探视时间" :model-value="
            item.appointVisitTime +
            ' ' +
            item.appointStartTime +
            '-' +
            item.appointEndTime
          " readonly class="cl_group" />
        </van-cell-group>
        <div class="weiTanshi">未探视</div>
      </div>
      <!--待探视的详细内容:n -->
      <van-divider>没有更多啦</van-divider>
    </van-pull-refresh>
  </div>
</template>

<script>
import { visit } from "@/api/appdetails/appdetails.js";
export default {
  name: "manvisitComponent",
  props: ['flag'],
  data() {
    return {
      data: [],
      isLoading: false
    };
  },
  watch: {
    flag() {
      if (this.flag) {
        this.manvisit();
      }
    }
  },
  created() {
    // this.ts = Date.now(); //当前的时间戳
    this.manvisit();
  },
  methods: {
    // 点击待探视的跳转
    getvisit(val) {
      this.$router.push({ path: "/tovisit", query: { id: val } });
    },
    // 待探视首页的数据接口
    async manvisit() {
      const res = await visit();
      this.data = res.data.data;
      var nowTime = new Date().getTime(); //当前的时间戳
      this.data.forEach((item) => {
        const startDateTime = Date.parse(`${item.appointVisitTime} ${item.appointStartTime}`)
        item.unix = startDateTime > nowTime? startDateTime - nowTime: 0;

      });
      // console.log(this.ts);
      // var time = Date.parse(this.ts); //把后台返回的时间转化为时间戳的形式
      // var mise = times - time; //相减
      // this.data.forEach((item) => {
      //   item.appointSubmitTime = mise;
      // });
    },
    // 下拉触发这个事件
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        this.manvisit()
        this.$emit('refreshVist',true)
      }, 1000);
    },

  },
};
</script>

<style scoped>
.ap {
  width: 95%;
  min-height: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.van-pull-refresh {
  width: 100%;
  min-height: 100%;
  overflow-y: auto;
}
.ap p {
  font-size: 2rem;
  margin-left: 1rem;
  font-weight: 600;
}

.cl_task {
  width: 100%;
  margin: 2rem auto 0;
  background-color: white;
  border-radius: 1rem;
  padding-bottom: 0.9rem;
  margin-bottom: 3rem;
  position: relative;
  /*box-shadow: 0.1rem 0.1rem 1rem 0.1rem #d1caca;*/
}

.weiTanshi {
  position: absolute;
  top: 41%;
  left: 79%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: #968cea;
}

.cl_task:last-child {
  margin-bottom: 7.2rem;
}

.cl_visitime {
  width: 95%;
  border-bottom: 0.5px solid #EEEEEE;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}
.cl_notin {
  display: flex;
  align-items: center;
}
.cl_notin span:nth-child(2) {
  margin-left: 0.5rem;
  font-weight: 600;
  font-size: 1.4rem;
  color: #212121;
}

.cl_time {
  color: aqua;
}

::v-deep .van-count-down {
  color: rgb(10, 232, 232);
  font-size: 1.5rem;
}

.cl_group {
  background-color: white;
  height: 3rem;
  font-size: 1.5rem;
}

.cl_sign {
  position: absolute;
  top: 15.5rem;
  right: 8.3rem;
}

.cl_sign i {
  font-size: 3rem !important;
}

.cl_sign .iconfonts {
  font-size: 1.8rem !important;
}

.iconfont {
  font-size: 1.5rem !important;
}

::v-deep .van-cell-group--inset {
  margin: 0;
}

::v-deep .van-field__label {
  margin-right: 0;
}

::v-deep .van-cell {
  padding: 0.5rem 1rem;
}
</style>
