<template>
  <div class="mour_app">
    <van-nav-bar
        title="视频预览"
        left-arrow
        @click-left="onClickLeft"
        class="cl_leftarrow"
    />
    <van-pull-refresh v-model="loading" @refresh="onRefresh">
      <div class="cl_m">序号</div>
      <van-radio-group v-model="checked">
        <van-radio
            :name="item.id"
            shape="square"
            v-for="(item, index) in file"
            :key="index"
        >
          <div class="cl_information">
            病区：{{ data.userArea }} 床号：{{ data.userBed }}
            {{ data.userName }}
            {{ item.createTime }}
          </div>
          <div class="cl_status recording" v-if="item.status == 1">
            视频正在录制中
          </div>
          <div class="cl_status generate" v-else-if="item.status == 2">
            视频正在生成中
          </div>
          <video v-else-if="item.status == 3" class="cl_video" controls preload :src="item.fileUrl" x5-playsinline
                 webkit-playsinline x5-video-player-type="h5" x5-video-player-fullscreen
                 x5-video-orientation="portraint" playsinline></video>
        </van-radio>
      </van-radio-group>
      <van-divider dashed>没有更多啦</van-divider>
    </van-pull-refresh>
    <div class="cl_button">
      <van-button class="cl_back" @click="onClickLeft">取消</van-button>
      <van-button class="cl_delete" @click="handleDelete">删除</van-button>
      <van-button class="cl_upload" @click="handleUpload">上传</van-button>
    </div>
  </div>
</template>

<script>
import {appOint} from "@/api/aubot";
import {deleteRecord, getHandle, queryRecord} from "@/api/rtc";
import {Dialog, Toast} from "vant";

export default {
  name: "playbackComponent",
  components: {},
  data() {
    return {
      val: -1,      // 废弃
      visitId: -1,  // 预约id
      data: {},  // 视频信息
      loading: false, //控制录制视频
      checked: "",  // 任务id
      file: [],   // 视频地址
    };
  },
  mounted() {
    this.val = this.$route.query.id;
    this.visitId = this.$route.query.visitId;
    this.getAppoint();
    this.getTask();
  },
  methods: {
    // 首页数据的获取
    async getAppoint() {
      const res = await appOint(this.visitId);
      if (res.data.code == 200) {
        this.data = res.data.data;
      } else {
        Toast.fail("获取信息失败");
      }
    },
    // 获取视频列表
    getTask() {
      queryRecord(this.visitId).then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          this.file = res.data.data;
          // document.getElementById("video").src = res.data.data.filefileUrl;
          //   var video = document.getElementsByClassName("cl_video");
          //   console.log(video, video.value.length);
          //   console.log(Array.prototype.slice.call(video));
          // for (let i = 0; i < video.length; i++) {
          //     video[i].src = this.file[i].filefileUrl;
          //     console.log(video[i]);
          // }
        } else {
          Toast.fail("视频加载失败！");
          setTimeout(() => {
            this.$router.push({path: "/tovisit", id: this.visitId});
          }, 2000);
        }
      });
    },
    onRefresh() {
      setTimeout(() => {
        this.getAppoint();
        this.getTask();
        Toast("刷新成功");
        this.loading = false;
      }, 1000);
    },
    // 上传
    handleUpload() {
      if (this.checked !== "") {
        Dialog.confirm({
          message: "确定上传？",
        }).then(() => {
          const data = {
            taskId: this.checked
          }
          getHandle(this.visitId, data).then(res => {
            if (res.data.code === 200) {
              Toast.success('上传成功！');
              setTimeout(() => {
                history.back();
              }, 2000);
            } else {
              Toast.fail('上传失败！');
            }
          })
        });
      } else {
        Toast.fail("请先勾选一条！");
      }
    },
    // 删除
    handleDelete() {
      if (this.checked !== "") {
        Dialog.confirm({
          message: "是否删除？",
        }).then(() => {
          console.log(this.checked);
          deleteRecord(this.checked).then(res => {
            console.log(res);
            if (res.data.code === 200) {
              Toast.success('删除成功！');
              setTimeout(() => {
                this.getAppoint();
                this.getTask();
              }, 2000);
            } else {
              Toast.fail('删除失败！' + (res.data.data?.error || ''))
            }
          })
        });
      } else {
        Toast("请先勾选一条！");
      }
    },
  },
  setup() {
    // 返回上一级
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
};
</script>

<style scoped>
.mour_app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* background-color: red; */
}

-webkit-scrollbar {
  display: none;
}

.van-pull-refresh {
  width: 90%;
  height: 93%;
  overflow: auto;
  margin: 0 auto 10rem;
  position: relative;
}
.cl_m {
  position: absolute;
  top: 1.2rem;
}

.van-radio-group {
  /* border: 1px solid rgb(0, 13, 255); */
  width: 100%;
  /* margin-bottom: 5rem; */
}

.van-divider {
  margin-top: 2rem;
  padding-bottom: 10rem;
}

.van-radio {
  /* border: 1px solid red; */
  width: 100%;
  margin-top: 2rem;
}

.cl_information {
  width: 100%;
  padding: 1rem;
  letter-spacing: 5px;
  margin-left: 1rem;
}

.cl_video {
  /* border: 1px solid red; */
  width: 100%;
  height: 15rem;
  margin-left: 1rem;
}

.cl_status {
  width: 100%;
  height: 15rem;
  margin-left: 1rem;
  text-align: center;
  line-height: 15rem;
  font-size: 2rem;
  font-weight: 550;
}

.recording {
  color: red;
}

.generate {
  color: blue;
}

/* 按钮 */
.cl_button {
  position: fixed;
  bottom: 0px;
  padding: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  z-index: 9999999;
}

.van-button {
  border-radius: 100px;
  color: white;
  width: 27%;
  border: none;
}

.cl_back {
  background-color: cadetblue;
  margin-left: 2rem;
}

.cl_delete {
  background-color: red;
}

.cl_upload {
  background-color: blue;
  margin-right: 2rem;
}
</style>
