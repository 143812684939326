<template>
  <div class="spp">
    <logoComponent></logoComponent>

    <div class="cl_form">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field v-model="account" name="account" label="用户名" placeholder="用户名" :rules="[{ required: true, message: '请填写用户名' }]" />
          <van-field v-model="password" type="password" name="password" label="密码" placeholder="密码" :rules="[{ required: true, message: '请填写密码' }]" />
        </van-cell-group>
          <van-button round block type="primary" native-type="submit" class="cl_button"> 提交 </van-button>
      </van-form>
    </div>
  </div>
</template>

<script>
import logoComponent from "./components/logo.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import { logins } from "@/api/login.js";
export default {
  name: "loginComponent",
  components: {
    logoComponent,
  },
  setup() {
    const account = ref("");
    const password = ref("");
    const router = useRouter();
    const onSubmit = async (values) => {
      const res = await logins(values);
      if (res.data.code == 200) {
        Toast.success("登录成功");
        // 本地存储token
        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("position", res.data.data.position);
        localStorage.setItem("manage", res.data.data.manage);
        localStorage.setItem("audit", res.data.data.manaauditge);
        setTimeout(() => {
          router.push({path: '/about'});
        }, 1000);
      } else {
        Toast.fail("登录失败");
      }
    };
    return {
      account,
      password,
      onSubmit,
    };
  },
};
</script>

<style scoped>
.spp {
  width: 100%;
  height: 100%;
  border: 1px solid #F9F9F9;
}
.cl_form {
  width: 80%;
  margin: 5rem auto 0;
  position: relative;
  padding: 1rem 1rem;
  background-color: white;
  border-radius: 1rem;
}

.cl_authentication {
  margin-bottom: 2rem;
  float: right;
  font-size: 2rem;
  font-weight: 500;
  color: aqua;
}
.cl_button {
  position: absolute;
  width: 80%;
  left: 50%;
  transform: translate(-50%);
  bottom: -20rem;
}
</style>
