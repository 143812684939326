<template>
  <div class="app">
    <titleComponent :title="title"></titleComponent>
    <detailsComponent :val="val"></detailsComponent>
    <div class="cl_choice">
      <van-button type="primary" class="cl_button" @click="getreject">驳回</van-button>
      <van-button type="primary" class="cl_button" @click="agree">同意</van-button>
    </div>
    <!-- 驳回的详情页:s -->
    <reasonComponent :flag="flag" @flag="fn" @query="query" ref="rsason"></reasonComponent>
    <!-- 驳回的详情页:n -->
  </div>
</template>
<script>
import titleComponent from '../title.vue'
import detailsComponent from "./components/details.vue";
import reasonComponent from "./components/reason.vue";
import { make, agRee } from "@/api/appdetails/appdetails.js";
export default {
  name: "appdetailsComponent",
  components: {
    detailsComponent,
    reasonComponent,
    titleComponent,
  },
  data() {
    return {
      title: '预约详情',
      flag: false,
      val: "",
    };
  },
  created() {
    this.val = this.$route.query.id;
    this.makes();
  },
  methods: {

    // 点击驳回的接口
    getreject() {
      if (this.flag) {
        this.flag = false;
      } else {
        this.flag = true;
      }
    },
    fn(data) {
      this.flag = data;
    },
    // 触发子组件的一个自定义事件、让弹框隐藏
    query() {
      this.flag = false;
    },

    // 点击同意发送的请求
    async agree() {
  console.log(this.$refs.rsason.appoIntions);
      const res = await agRee(this.val);
      console.log(res);
      // if (res.status == "200") {
      //   this.$toast("确认成功");
      //   setTimeout(() => {
      //     this.$router.push("/news");
      //   }, 2000);
      // }
    },

    // 预约详情首页获取的数据
    async makes() {
      // console.log(this.val);
      const res = await make(this.val);
      console.log(res);
    },

    // 点击返回上一级
    onClickLeft() {
      this.$router.push("/about");
    },
  },
  mounted() {
    this.val = this.$route.query.id;
  },
  // setup() {
  //   // 返回上一级
  //   const onClickLeft = () => history.back();
  //   return {
  //     onClickLeft,
  //   };
  // },
};
</script>

<style scoped>
.app {
  width: 100%;
  height: 100%;
  border: 1px solid #f5f5f7;
}
.cl_choice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  position: fixed;
  z-index: 999;
  bottom: 10rem;
  left: 50%;
  transform: translateX(-50%);
}
.cl_button {
  width: 47%;
  height: 5rem;
  border: none;
  font-weight: 600;
  font-size: 1.6rem;
  color: white;
  border: none;
  border-radius: 20px;
}
.cl_choice .cl_button:first-child {
  background-color: #DF1642;
}

::v-deep .van-nav-bar__content {
  height: 4.6rem;
}
::v-deep .van-nav-bar__title {
  font-size: 1.5rem;
  color: #fff;
}
::v-deep .van-nav-bar .van-icon {
  color: #fff;
}

::v-deep .cl_data_field {
  line-height: 5rem;
}
</style>
