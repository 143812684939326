<template>
  <div class="app">
<!--    <van-nav-bar title="消息" @click-left="onClickLeft" class="cl_leftarrow" />-->
    <taskComponent></taskComponent>
  </div>
</template>

<script>
import taskComponent from "./components/receivetask.vue";
export default {
  name: "newsComponent",
  components: {
    taskComponent,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.app {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
/*.cl_leftarrow {*/
/*  width: 100%;*/
/*  height: 5rem;*/
/*  position: fixed;*/
/*  top: 0%;*/
/*  left: 0%;*/
/*  font-size: 3rem;*/
/*  background-color: #00bfff;*/
/*}*/
</style>
