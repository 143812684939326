import request from '@/utils/request'
import qs from "qs";

/**
 * 获取rtc信息
 * @param appointmentId 预约id
 * @param userId 用户id(可选)
 * @returns {*}
 */
export function getRtcAuth(appointmentId, userId) {
  const url = `care/rtc/getRtcAuth/${appointmentId}?` + qs.stringify({userId});
  return request({url, method: 'get'})
}

/**
 * 启动录制
 * @param appointmentId 预约id
 * @returns {*}
 */
export function startRecord(appointmentId) {
  const url = `care/rtc/startRecord/${appointmentId}`;
  return request({url, method: 'get'})
}

/**
 * 停止录制
 * @param appointmentId 预约id
 * @returns {*}
 */
export function stopRecord(appointmentId) {
  const url = `care/rtc/stopRecord/${appointmentId}`;
  return request({url, method: 'get'})
}

/**
 * 查询视频录制任务
 * @param id 预约id
 * @returns {*}
 */
export function queryRecord(id) {
  const url = `care/rtc/queryRecordTask/${id}`;
  return request({url, method: 'get'})
}

/**
 * 删除视频录制任务
 * @param id 任务id
 * @returns {*}
 */
export function deleteRecord(id) {
  const url = `care/rtc/deleteRecordTask/${id}`;
  return request({url, method: 'delete'})
}

/**
 * 预约提交视频接口
 * @param id 预约id
 * @param params 任务id
 * @returns {*}
 */
export function getHandle(id, params) {
  const url = `/care/handle/video/${id}`;
  return request({url, method: 'post', params})
}
