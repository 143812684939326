<template>
	<div class="ap">
		<div class="">
			<van-nav-bar
				title="已结束"
				left-arrow
				@click-left="onClickLeft"
				class="cl_leftarrow"
			/>
		</div>
		
		<backComponent :val="val"></backComponent>
	</div>
</template>

<script>
	import backComponent from './components/back.vue'
	export default {
		name: 'playbackComponent',
		components: {
			backComponent,
		},
		data() {
			return {
				val: -1,
			}
		},
		setup() {
			// 返回上一级
			const onClickLeft = () => history.back();
			return {
			onClickLeft,
			};
		},
		mounted() {
			this.val = this.$route.query.id;
		}
	}
	
</script>

<style scoped>
	.ap {
		width: 95%;
		margin: 0 auto;
	}
	.cl_leftarrow {
		/* border: 1px solid red; */
		width: 95%;
		margin: 1rem auto;
		font-size: 3rem;
	}
</style>