export const timeFmt = (time, fmt) => {
  let h = Math.floor(time / (60 * 60));
  let hRemain = time % (60 * 60);
  let m = Math.floor(hRemain / 60);
  let mRemain = hRemain % 60;
  let s = mRemain;

  let obj = {
    "h+": ('00' + h).substr(-2),
    "m+": ('00' + m).substr(-2),
    "s+": ('00' + s).substr(-2),
  }

  for (let key in obj) {
    let pat = `(${key})`
    if (new RegExp(pat).test(fmt)) {
      let str = obj[key] + '';
      // RegExp.$1 hh mm ss贪婪匹配
      fmt = fmt.replace(RegExp.$1, str)
    }
  }
  return fmt;
}


export function getFileType(filePath) {
  var startIndex = filePath.lastIndexOf(".");
  if (startIndex !== -1)
    return filePath.substring(startIndex + 1, filePath.length).toLowerCase();
  else return "";
}
