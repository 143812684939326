<template>
  <div class="ap">
    <div class="cl_ribect">探视对象</div>
    <div class="cl_max_round">
      <div class="cl_round">
        <van-image round width="7rem" height="7rem" :src="data.headimage" />
        <p>{{ data.userName }}</p>
      </div>
      <van-cell-group inset class="cl_max_group">
        <!-- <van-field
          label="病案号"
          :model-value="data.recordnum"
          readonly
          class="cl_group"
          label-width="5rem"
        /> -->
        <van-field
          label="床号"
          :model-value="data.userBed"
          readonly
          class="cl_group"
          label-width="5rem"
        />
        <van-field
          label="病区"
          :model-value="data.userArea"
          readonly
          class="cl_group"
          label-width="5rem"
        />
        <van-field
          label="电话"
          :model-value="data.userAccount"
          readonly
          class="cl_group"
          label-width="5rem"
        />
      </van-cell-group>
    </div>
    <div class="cl_two_visitime">
      <div>探视时间</div>
      <div>{{ data.appointVisitTime }}</div>
    </div>
    <div class="cl_choice">
      <van-button round type="primary" class="cl_button">探视回放</van-button>
    </div>
  </div>
</template>

<script>
import { appOint } from "@/api/aubot";
export default {
  name: "backComponent",
  data() {
    return {
      data: {},
      id: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getBack();
  },
  methods: {
    getvisit(val) {
      this.$router.push({ path: "/tovisit", query: { id: val } });
    },
    async getBack() {
      const res = await appOint(this.id);
      this.data = res.data.data;
    },
  },
  props: {
    val: String,
  },
};
</script>

<style scoped>
.ap {
  width: 95%;
  margin: 0 auto;
}

.ap > p {
  font-size: 2rem;
  margin-left: 1rem;
  font-weight: 600;
}

.cl_ribect {
  font-size: 2rem;
  margin: 2rem 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid black;
}

.cl_max_round {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cl_max_group {
  width: 55%;
}

.cl_round {
  margin: 1rem auto;
  text-align: center;
  transform: translateY(10%);
}

.cl_round > p {
  font-size: 2rem;
}

.cl_two_visitime {
  width: 90%;
  margin: 3rem auto 0;
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  font-weight: 500;
}

.cl_two_visitime div:first-child {
  font-weight: 600;
}

.cl_two_visitime div:last-child {
  color: aqua;
}

.cl_choice {
  width: 90%;
  margin: 5rem auto 0;
  /* border: 1px solid red; */
}

.cl_button {
  width: 45%;
  border: none;
  font-weight: 600;
  font-size: 1.5rem;
  color: white;
  background-color: red;
  left: 50%;
  transform: translate(-50%);
}
</style>
