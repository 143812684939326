<template>
	<div class="app">
		<div v-show="!flag">
			<van-cell-group inset>
				<van-field v-model="data.name" label="姓名" placeholder="请填写姓名" required label-class="cl_ddit"/>
				<div class="cl_rusern">
					<p class="cl_quxi">性别：</p>
					<van-radio-group v-model="checked" direction="horizontal">
						<van-radio name="1">男</van-radio>
						<van-radio name="2">女</van-radio>
					</van-radio-group>
				</div>
				<van-field v-model="data.age" type="digit" label="年龄" placeholder="请输入年龄" required label-class="cl_ddit"/>
				<van-field v-model="data.phone" type="tel" label="手机号码" placeholder="请输入手机号" required label-class="cl_ddit"/>
				<van-field v-model="data.bednum" type="digit" label="床号" placeholder="请输入床号" required label-class="cl_ddit"/>
				<van-field v-model="data.recordnum" type="digit" label="病案号" placeholder="请输入病案号" required label-class="cl_ddit"/>
			</van-cell-group>
			
			
			<van-button type="primary" size="large" @click="getedit">编辑</van-button>
		</div>
		
		<div v-show="flag">
			<van-cell-group inset>
				<van-field v-model="data.name" label="姓名" placeholder="请填写姓名" required label-class="cl_ddit"/>
				<div class="cl_rusern">
					<p class="cl_quxi">性别：</p>
					<van-radio-group v-model="checked" direction="horizontal">
						<van-radio name="1">男</van-radio>
						<van-radio name="2">女</van-radio>
					</van-radio-group>
				</div>
				<van-field v-model="data.age" type="digit" label="年龄" placeholder="请输入年龄" required label-class="cl_ddit"/>
				<van-field v-model="data.phone" type="tel" label="手机号码" placeholder="请输入手机号" required label-class="cl_ddit"/>
				<van-field v-model="data.bednum" type="digit" label="床号" placeholder="请输入床号" required label-class="cl_ddit"/>
				<van-field v-model="data.recordnum" type="digit" label="病案号" placeholder="请输入病案号" required label-class="cl_ddit"/>
			</van-cell-group>
			
			
			<van-button type="primary" size="large" @click="onConfirm">保存</van-button>
		</div>
	</div>
</template>

<script>
	import {
		ref
	} from 'vue';

	import {reactive} from 'vue';
	// import { useRouter } from 'vue-router';
	export default {		
		name: 'editComponent',
		props: {
			val: {
				required: true,
			}
		},
		setup() {
			let data = reactive({
				name: '张三',
				age: '19',
				phone: '18438562384',
				bednum: '14',
				recordnum: '22',
				sex: '1',
			});
			const checked = ref(data.sex);
			// const router = useRouter();
			let flag = ref(false);
			function getedit() {
				// console.log(flag,flag.value);
				if (flag.value) {
					flag.value = false;
				} else {
					flag.value = true;
				}
			}
			return {
				data,
				checked,
				getedit,
				flag,
			};
		},	

	}
</script>

<style scoped>
	.ap {
		width: 100%;

	}
	::v-deep .van-cell-group {
		width: 80%;
		margin: 4rem auto 2rem;
		/* border: 1px solid red; */
	}
	::v-deep .van-field {
		font-size: 1.6rem;
		height: 6rem;
	}
	::v-deep .cl_ddit {
		width: 9rem;
		font-weight: 900;
	}
	/* 权限 */
	.cl_rusern {
		display: flex;
		width: 100%;
		margin: 1rem auto;
		/* border: 1px solid red; */
	}
	.cl_quxi {
		font-weight: 550;
		font-size: 1.6rem;
		margin-left: 2.5rem;
	}
	::v-deep .van-radio-group {
		font-size: 1.6rem;
		margin-left: 4rem;
	}
	::v-deep .van-radio:last-child {
		margin-left: 2rem;
	}
	::v-deep .van-button {
		position: fixed;
		bottom: 6rem;
		width: 70%;
		left: 50%;
		transform: translate(-50%);
		border-radius: 10rem;
	}
</style>
