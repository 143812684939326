<template>
	<div class="app">
			<van-nav-bar :title="title" left-arrow @click-left="onClickLeft" class="cl_leftarrow" />
			<add-component v-show="flag"></add-component>
			<edit-component v-show="!flag" :val="val"></edit-component>
	</div>
</template>

<script>
	import addComponent from './components/add.vue';
	import editComponent from './components/edit.vue';
	
	export default {
		name: 'addtoComponent',
		components: {
			addComponent,
			editComponent,
		},
		data() {
			return {
				flag: false,
				val: -1,
				title: '',
			}
		},
		
		mounted() {
			this.val = this.$route.query.id;
			if (this.$route.query.id == undefined) {
				this.flag = true;
				this.title = '添加';
			} else {
				this.flag = false;
				this.title = '编辑';
			}
		},
		setup() {
			// 返回上一级
			const onClickLeft = () => history.back();
			return {
			onClickLeft,
			};
		},
	}
</script>

<style scoped>
	.ap {
		width: 100%;
		/* margin: 0 auto; */
	}
	.cl_leftarrow {
		/* border: 1px solid red; */
		width: 100%;
		height: 5rem;
		margin: 1rem auto;
		font-size: 3rem;
		background-color: 	#00BFFF;
	}

	
</style>
