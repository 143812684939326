<template>
  <div class="app">
    <van-nav-bar title="我的任务" left-arrow @click-left="onClickLeft" class="cl_leftarrow" />

    <!-- 我的页面:s -->
    <van-tabs v-model:active="activeName" animated swipeable>

      <van-tab title="全部" name="1">
      <taskList></taskList>
      </van-tab>

      <van-tab title="待探视" name="2">
       <danTanshi></danTanshi>
      </van-tab>

      <!-- 已结束:s -->
      <van-tab title="已结束" name="3">
        <manVist></manVist>
      </van-tab>
      <!-- 已结束:n -->

    </van-tabs>
    <!-- 我的页面:n -->

  </div>
</template>

<script>
import manVist from './components/manViet.vue'
import danTanshi  from './components/dauTanshi.vue'
import taskList  from './components/task.vue'
export default {
  name: "mytaskComponent",
  components: {
    manVist,
    danTanshi,
    taskList
  },
  data() {
    return {
      flag: false,
      // id: 1,
    };
  },

  setup() {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
};
</script>

<style scoped>
.ap {
  width: 100%;
}

.cl_leftarrow {
  width: 100%;
  height: 5rem;
  margin: 1rem auto;
  font-size: 3rem;
  background-color: #00bfff;
}
</style>
