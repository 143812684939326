<template>
  <div class="ap">
    <van-nav-bar title="详情" left-arrow @click="onClick" class="cl_leftarrow" />
    <detailsComponent :val="val"></detailsComponent>
    <div class="cl_choice">
      <van-button round type="primary" class="cl_button" @click="choice"
        >接收任务</van-button
      >
    </div>
  </div>
</template>

<script>
import { receive } from "@/api/appdetails/appdetails.js";
import detailsComponent from "./components/details.vue";
export default {
  name: "taskdetailsComponent",
  components: {
    detailsComponent,
  },
  data() {
    return {
      val: "",
    };
  },
  beforeCreate(){
  },
  created() {
  //  window.addEventListener('beforeunload', e => this.test(e)) 
    this.val = this.$route.query.id;
  },
  mounted() {
    this.val = this.$route.query.id;
  },
  methods: {
    // 接收任务发送的请求
    async choice() {
      const res = await receive(this.val);
      if (res.status == 200) 
        this.$toast("接收任务成功");
        setTimeout(() => {
          this.$router.push({path: '/about'});
        }, 1000);
      },
      onClick() {
      this.$router.back();
    },
    },
};
</script>

<style scoped>
.ap {
  width: 100%;
  /* margin: 0 auto; */
  padding-top: 0rem;
}
.cl_leftarrow {
  /* border: 1px solid red; */
  width: 100%;
  height: 5rem;
  /* margin: 1rem auto; */
  font-size: 3rem;
  background-color: #00bfff;
}
.cl_choice {
  width: 90%;
  margin: 5rem auto 0;
  /* border: 1px solid red; */
}
.cl_button {
  width: 60%;
  border: none;
  font-weight: 600;
  font-size: 1.5rem;
  color: white;
  background-color: #1e90ff;
  left: 50%;
  height: 5rem;
  transform: translate(-50%);
}
::v-deep .van-nav-bar__title {
  color: #fff;
  font-size: 1.5rem;
}
::v-deep .van-nav-bar .van-icon {
  color: #fff;
}
::v-deep .van-nav-bar__content {
  height: 4.45rem;
}
::v-deep .van-cell {
  line-height: 6rem;
}
</style>
