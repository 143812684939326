<template>
  <div class="ap">
    <div class="cl_boirer">
      <div class="cl_visitime">
        <div class="cl_notin">
          <span><img src="../../../assets/Style.png" alt="" style="width: 1.8rem;height: 1.8rem"></span><span>待探视</span>
        </div>
        <div class="cl_time">
          <van-count-down :time="time" format="DD 天 HH 时 mm 分 ss 秒"/>
        </div>
      </div>
      <div class="cl_max_round">
        <div class="cl_round">
          <img :src="image" />
          <p>{{ data.userName }}</p>
        </div>
        <van-cell-group inset class="cl_max_group">
          <van-field label="床号" :model-value="data.userBed" readonly class="cl_group" label-width="5rem"/>
          <van-field label="病区" :model-value="data.userArea" readonly class="cl_group" label-width="5rem"/>
          <van-field label="电话" :model-value="data.userAccount" readonly class="cl_group" label-width="5rem"/>
        </van-cell-group>
      </div>
      <div class="cl_two_visitime">
        <div>探视时间</div>
        <div class="cltwo-item">
          {{
            data.appointVisitTime +
            " " +
            data.appointStartTime +
            " - " +
            data.appointEndTime
          }}
        </div>
      </div>
    </div>
    <div class="cl_choice">
      <van-button round type="primary" class="cl_button" @click="getVistad(data.appointId)">探视录制</van-button>
      <van-button round type="primary" class="cl_button" @click="getplayback(data.appointId)">视频预览</van-button>
    </div>
    <!-- RTC推拉流视频录制 -->
    <div v-show="enabl">
      <rtc-record :appointment-id="data.appointId" @generate="enabl = false"/>
    </div>
  </div>
</template>

<script>
import { appOint, getavatar } from "@/api/aubot";
import {Toast} from "vant";
import {handleVideo} from "@/api/visit";
import RtcRecord from "@/components/rtc-record";

export default {
  name: "relsourComponent",
  components: {
    RtcRecord
  },
  data() {
    return {
      data: {},
      visitId: "",
      time: 0,
      show: false,
      fole: {
        id: ''
      },
      enabl: false,
      image: 'https://img1.baidu.com/it/u=823780333,746592565&fm=253&fmt=auto&app=138&f=JPEG?w=440&h=440',
    };
  },
  created() {
    this.visitId = this.$route.query.id;
    // console.log(this.$route.query.id);
    this.getAppoint();
    getavatar().then(res => {
      console.log(res)
      if(res.data.code == 200) {
        this.image = res.data.data.avatarUrl;
      }
    })
  },
  methods: {
    // 进入视频预览的操作
    getplayback(val) {
      this.$router.push({path: "/preview", query: {id: val, visitId: this.visitId}});
    },

    // 首页数据的获取
    async getAppoint() {
      const res = await appOint(this.visitId);
      console.log(res)
      this.data = res.data.data;

      // 当前的时间
      const nowTime = new Date().getTime();
      const {appointVisitTime, appointStartTime} = this.data;
      const startDateTime = Date.parse(`${appointVisitTime} ${appointStartTime}`)
      this.time = startDateTime > nowTime ? startDateTime - nowTime : 0;
    },

    // 视频的录制
    getVistad(id) {
      console.log(id, this.visitId);
      // 普通视频录制
      // this.fole.id = id
      // console.log(this.fole.id);
      // this.show = true;
      // // console.log('inputVideo', this.$refs.inputVideo)
      // this.$refs.inputVideo.click();
      this.enabl = true;
    },

    //视频录制结束后将视频路径赋值给video标签进行播放
    videoChange() {
      var filesId = document.getElementById("video-input");
      const file = filesId.files[0];

      handleVideo(file, this.fole.id).then((res) => {
        Toast.clear();
        if (res.data.code === 200) {
          Toast.success("上传成功");
          setTimeout(() => {
            this.$router.back(0)
          }, 2000)
        } else {
          Toast.fail("上传失败！");
        }
      }).catch((e) => {
        console.error("上传异常！", e)
        Toast.clear();
        Toast.fail("上传异常！")
      })

      Toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0
      });
    },
    deleter() {
      this.show = false;
    },
  },
};
</script>

<style scoped>
.ap {
  width: 95%;
  margin: 6rem auto 0;
}
.cl_boirer {
  width: 100%;
  background-color: white;
  border-radius: 20px;
}
.div-Video {
  position: absolute;
  top: 83%;
  left: 62%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

#video {
  position: fixed;
  top: 25%;
  left: 30%;
  transform: translate(-25%, -25%);
  border-radius: 1rem;
}

.ap > p {
  font-size: 2rem;
  margin-left: 1rem;
  font-weight: 600;
}

.cl_visitime {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #EEEEEE;
  padding: 0.7rem 0;
}
.cl_notin {
  display: flex;
  align-items: center;
}

.cl_notin span:nth-child(2) {
  margin-left: 1rem;
  font-weight: 600;
  font-size: 1.4rem;
  color: #212121;
}
.cltwo-item {
  padding-top: 0.3rem !important;
  margin-left: 2.5rem;
}

.iconfont {
  font-size: 1.5rem !important;
}

.cl_visitime span:nth-child(2) {
  margin-left: 1rem;
  font-weight: 600;
}

.cl_time {
  color: aqua;
  padding-top: 0.3rem;
}

.van-count-down {
  color: #1283f3 !important;
  font-size: 1.5rem;
  margin-left: 1.5rem;
  font-weight: 500;
}


.cl_max_round {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cl_max_group {
  width: 55%;
}

.cl_round {
  margin: 1rem auto;
  text-align: center;
  transform: translateY(10%);
}
.cl_max_round .cl_round img {
  width: 6rem;
  height: 6rem;
  border-radius: 20px;
}
.cl_round > p {
  font-size: 2rem;
}

.cl_two_visitime {
  width: 95%;
  border-top: 0.5px solid #EEEEEE;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0 auto;
  padding: 1rem 0;
}

.cl_two_visitime div:first-child {
  font-weight: 600;
  margin-left: 1rem;
}

.cl_two_visitime div:last-child {
  color: aqua;
  margin-left: 9rem;
}

/* 两个按钮 */
.cl_choice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10rem;
}

.cl_button {
  width: 45%;
  border: none;
  font-weight: 600;
  font-size: 1.5rem;
  color: white;
  border-radius: 20px;
}

.cl_choice .cl_button:first-child {
  background-color: #FFBB38;
}

.cl_choice .cl_button:last-child {
  background-color: blue;
}
</style>
