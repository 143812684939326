import request from '@/utils/request'
import {Toast} from "vant";
import {getFileType, timeFmt} from "@/utils/common";

const OSS = require('ali-oss');


export const getAssumeRole = async () => {
  const assumeRoleRes = await request({url: "care/handle/assumeRole", method: "get"});
  console.log('assumeRoleRes', assumeRoleRes);
  if (!assumeRoleRes.data || !assumeRoleRes.data.data) {
    Toast.fail('鉴权失败！');
    return;
  }

  const assumeRole = assumeRoleRes.data.data;
  console.log('assumeRole', assumeRole);
  return assumeRole;
}

export const newOssClient = (assumeRole) => {
  if (!assumeRole) {
    return false;
  }

  const {accessKeyId, accessKeySecret, secretToken, region, bucket} = assumeRole;

  return new OSS({
    // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
    region: region,
    // 从STS服务获取的安全令牌（SecurityToken）。
    stsToken: secretToken,
    // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
    accessKeySecret,
    accessKeyId,
    // 填写Bucket名称。
    bucket

    /*// 刷新临时访问凭证的时间间隔，单位为毫秒。
    refreshSTSTokenInterval: 300000,*/

  });
}

/**
 * 分片上传
 * @param ossClient oss客户端
 * @param filePath 文件上传路径
 * @param file 文件
 * @returns {*} 上传结果
 */
const multipartUpload = (ossClient, filePath, file) => {
  console.log('multipartUpload...')
  return ossClient.multipartUpload(filePath, file, {
    // 设置并发上传的分片数量。
    parallel: 10,
    // 设置分片大小。默认值为1 MB，最小值为100 KB。
    partSize: 1024 * 1024,
  });
}


export const uploadFile2Oss = async (file, id) => {
  const assumeRole = await getAssumeRole();
  if (!assumeRole) {
    return false;
  }
  const ossClient = newOssClient(assumeRole);

  const {uploadDir} = assumeRole;
  const uploadPath = `${uploadDir}${id}.${getFileType(file.name)}`
  console.log('uploadPath', uploadPath)

  // 小于100M使用简单上传
  const threshold = 1024 * 1024 * 100;
  const s = Date.now();

  try {
    const result = file.size < threshold ?
      await ossClient.put(uploadPath, file) :
      await multipartUpload(ossClient, uploadPath, file);

    console.log(`上传${(file.size / 1024 / 1024).toFixed(2)}M 耗时：${timeFmt(Date.now() - s, 'mm:ss')}`)
    console.log('ossClient.put result', result)
    return result;
  } catch (e) {
    Toast.fail('上传失败！');
    console.error('ossClient.put err', e)
  }

  return false;
}

export const handleVideo = async (file, id) => {
  const uploadRes = await uploadFile2Oss(file, id);
  if (!uploadRes) {
    return false;
  }
  // {
  //     "name": "quzhou/remote-visit/63036cd862b80b34be38051a.mp4",
  //     "url": "http://tongyuan-front.oss-cn-hangzhou.aliyuncs.com/quzhou/remote-visit/63036cd862b80b34be38051a.mp4",
  //     "res": {
  //         "status": 200,
  //         "statusCode": 200,
  //         "headers": {
  //             "content-length": "0"
  //         },
  //         "size": 0,
  //         "aborted": false,
  //         "rt": 8910,
  //         "keepAliveSocket": false,
  //         "data": {
  //             "type": "Buffer",
  //             "data": []
  //         },
  //         "requestUrls": [
  //             "http://tongyuan-front.oss-cn-hangzhou.aliyuncs.com/quzhou/remote-visit/63036cd862b80b34be38051a.mp4"
  //         ],
  //         "timing": null,
  //         "remoteAddress": "",
  //         "remotePort": ""
  //
  //     }
  // }

  const {url} = uploadRes;
  const data = new FormData()
  data.append('url', url)
  const headers = {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"};
  return await request({url: `care/handle/video/${id}`, headers, method: "post", data});
}
