<template>
	<div class="azp">
			<van-nav-bar :title="title" left-arrow @click-left.stop="onClickLeft" class="cl_leftarrow" />
	</div>
</template>

<script>
	export default {
		name: 'titleComponent',
		props: {
			title: {
				required: true,
			}
		},

		setup() {
			// 返回上一级
			const onClickLeft = () => history.back();
			return {
				onClickLeft,
			};
		},
	}
</script>

<style scoped>
	.azp {
		width: 100%;
	}

	.cl_leftarrow {
		position: fixed;
		top: 0;
		z-index: 9999;
		width: 100%;
		font-size: 30rem;
		background-color: white;
	}
  /deep/ .van-nav-bar__content .van-nav-bar__title {
    color: black;
  }
	::v-deep .van-nav-bar__content .van-icon-arrow-left:before {
		color: black;
	}
</style>
