<template>
  <div class="asp">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
<!--      <p>待审核</p>-->
      <!-- 展示数据的内容部分:s -->
      <div class="cl_task" v-for="item in data" :key="item.appointId">
        <div class="cl_title_time">
          <img src="../../../assets/tr6.png" alt="" style="width: 1.8rem;height: 1.8rem;">
          <p class="cl_tite cl_titk">提交申请时间</p>
          <p class="cl_tite cl_date">{{ item.appointSubmitTime }}</p>
        </div>
        <van-cell-group inset class="cl_max_group">
<!--          <van-field label="提交申请时间" :model-value="item.appointSubmitTime" readonly class="cl_group" />-->
          <van-field label="预约人" :model-value="item.userName" readonly class="cl_group" />
          <van-field label="电话" :model-value="item.userAccount" readonly class="cl_group" />
          <van-field label="探视时间" :model-value="
            item.appointVisitTime +
            ' ' +
            item.appointStartTime +
            ' ' +
            item.appointEndTime
          " readonly class="cl_group" />
        </van-cell-group>
        <div class="cl_choice">
          <van-button type="primary" class="cl_button" @click="getreject(item.appointId)">驳回</van-button>
          <van-button type="primary" class="cl_button" @click="agRees(item.appointId)">同意</van-button>
        </div>
      </div>
      <!-- 展示数据的内容部分:n-->
      <van-divider>没有更多啦</van-divider>
    </van-pull-refresh>
  </div>
</template>

<script>
import { agRee } from "@/api/appdetails/appdetails.js";
import { Toast } from "vant";
export default {
  props: {
    data: {
      required: true,
      type: Array
    }
  },
  name: "manexamineComponent",
  data() {
    return {
      isLoading: false,
    };
  },
  created() {

  },
  methods: {
    // 点击驳回的接口
    getreject(val) {
      this.$router.push({
        path: "/appdetails",
        query: {
          id: val,
        },
      });
    },
    // 点击同意发送的请求
    async agRees(valueId) {
      const res = await agRee(valueId);
      if (res.status == "200") {
        Toast.success('已同意')
        setTimeout(() => {
          this.$router.push('news')
        }, 1000)
      }
    },
    // 下拉刷新会触发这个事件
    onRefresh() {
        setTimeout(() => {
          this.isLoading = false;
          this.$emit('onRefresh',true)
        }, 1000);
      },
  },

};
</script>

<style scoped>
.asp {
  width: 95%;
  margin: 0 auto;
  min-height: 100%;
  overflow: hidden;
}
.van-pull-refresh {
  width: 100%;
  min-height: 90%;
  overflow-y: auto;
}
.ap p {
  font-size: 2rem;
  margin-left: 1rem;
  font-weight: 600;
}

.cl_task {
  width: 100%;
  margin: 2rem auto 0;
  background-color: white;
  border-radius: 1rem;
  margin-bottom: 3rem;
  /*box-shadow: 0.1rem 0.1rem 1rem 0.1rem #d1caca;*/
  /* 从左到右 水平位置，垂直位置，模糊程度，阴影大小，阴影颜色*/
}
.cl_title_time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  border-bottom: 0.5px solid #EEEEEE;
  padding: 0 1rem;
}
.cl_title_time .cl_tite {
  font-weight: 400;
  font-size: 1.4rem;
  color: #212121;
}
.cl_title_time img {
  margin-left: -1rem;
}
.cl_title_time  .cl_titk {
  margin-left: -4rem;
}
.cl_title_time .cl_date {
  margin-left: 4rem;
}
.cl_task:last-child {
  margin-bottom: 7rem;
}

.cl_group {
  background-color: white;
  height: 3rem;
  font-size: 1.45rem;
}
/*按鈕*/
.cl_choice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  margin: 2rem auto 0;
  padding: 1.5rem 0;
  border-top: 0.5px solid #EEEEEE;
}

.cl_button {
  width: 47%;
  font-weight: 530;
  font-size: 1.4rem;
  border-radius: 10px;
  height: 4rem;
}

.cl_choice .cl_button:first-child {
  border: 1px solid #DF1642;
  color: #DF1642;
  background: white;
}

.cl_choice .cl_button:last-child {
  color: white;
  border: none;
  background: #18A957;
}

::v-deep .van-cell-group--inset {
  margin: 0;
}

::v-deep .van-field__label {
  margin-right: 0;
}
</style>
