import axios from "axios";
const baseURL = {
    production: "/api/",
    // development: "https://visit.zjtytech.cn/",
    // development: "http://192.168.50.66:8081",
    // development: "http://rv_mobile_nurse.demo.zjtytech.cn/api/",
    development: "/api/",
}[process.env.NODE_ENV || "development"];

const service = axios.create({
    baseURL, // url = base url + request url
    timeout: 50000, // request timeout
});

export default service;

service.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers["token"] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);