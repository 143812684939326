<template>
  <!--消息列表的数据:s -->
  <div class="dap">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="cl_task" v-for="item in data" :key="item.appointId">
        <div class="cl_title_time">
          <img src="../../../assets/tr6.png" alt="" style="width: 1.8rem;height: 1.8rem">
          <p class="cl_tite cl_titk">提交申请时间</p>
          <p class="cl_tite cl_date">{{ item.appointSubmitTime }}</p>
        </div>
        <van-cell-group
          inset
          class="cl_max_group"
          @click="getGroup(item.appointId)"
        >
<!--          <van-field-->
<!--            label="提交申请时间"-->
<!--            :model-value="item.appointVisitTime"-->
<!--            readonly-->
<!--            class="cl_group"-->
<!--          />-->
          <van-field
            label="预约人"
            :model-value="item.userName"
            readonly
            class="cl_group"
          />
          <van-field
            label="电话"
            :model-value="item.userAccount"
            readonly
            class="cl_group"
          />
          <van-field
            label="探视时间"
            :model-value="
              item.appointVisitTime +
              ' ' +
              item.appointStartTime +
              '-' +
              item.appointEndTime
            "
            readonly
            class="cl_group"
          />
        </van-cell-group>
        <div class="cl_button-xls">
          <van-button type="primary" class="cl_button" @click="getTask(item.appointId)">接收任务</van-button>
        </div>
      </div>
      <van-divider>没有更多啦</van-divider>
    </van-pull-refresh>
  </div>
  <!--消息列表的数据:n -->
</template>

<script>
import { tidings } from "@/api/appdetails/appdetails.js";
import { receive } from "@/api/aubot";
export default {
  name: "taskComponent",
  data() {
    return {
      data: [],
      isLoading: false,
    };
  },
  created() {
    this.getTidings();
  },
  methods: {
    // 点击接收任务的请求
    async getTask(id) {
      const res = await receive(id);
      if (res.data.code == 200) {
        this.$toast("接收任务成功");
        this.getTidings();
        setTimeout(() => {
          this.$router.push({ path: "/about", query: { name: "3" } });
        }, 1000);
      } else {
        this.$toast("接收任务失败");
      }
    },

    // 获取首页列表的数据
    async getTidings() {
      const res = await tidings();
      this.data = res.data.data;
    },
    // 点击页面内容的时候
    getGroup(id) {
      this.$router.push({ path: "/yuTobal", query: { val: id } });
      this.getTidings();
    },

     // 下拉刷新会触发这个事件
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
         this.getTidings()
      }, 1000);
    },
  },
};
</script>

<style scoped>
.dap {
  width: 95%;
  margin: 2rem auto;
}
.cl_task {
  width: 100%;
  margin: 0 auto 2rem;
  background-color: white;
  border-radius: 0.5rem;
}
.cl_title_time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 0 auto;
  border-bottom: 0.5px solid #EEEEEE;
  padding: 0 1rem;
}
.cl_title_time .cl_tite {
  font-weight: 400;
  font-size: 1.4rem;
  color: #212121;
}
.cl_title_time  .cl_titk {
  margin-left: -5rem;
}
.cl_title_time .cl_date {
  margin-right: 1rem;
}
.cl_group {
  background-color: white;
  height: 3rem;
  font-size: 1.4rem;
}
.cl_task .cl_button-xls {
  width: 95%;
  border-top: 0.5px solid #EEEEEE;
  padding: 1rem 0;
  margin: 1rem auto 0;
}
.cl_button {
  width: 100%;
}
.van-divider {
  margin: 3rem 0 5rem 0;
  /*padding-bottom: 10rem;*/
}
</style>
