<template>
	<div class="app">

		<van-cell-group inset>
			<van-field label="姓名" :model-value="users" readonly colon label-class="cl_ddit" @click="getshow" />
			<van-field label="电话" model-value="输入框只读" readonly colon label-class="cl_ddit"/>
		</van-cell-group>
		<div class="cl_rusern">
			<p class="cl_quxi">权限：</p>
			<van-radio-group v-model="checked" direction="horizontal">
				<van-radio name="1">用户审核</van-radio>
				<van-radio name="2">用户管理</van-radio>
			</van-radio-group>
		</div>
		
		<van-button type="primary" size="large">确认</van-button>
		
		<!-- 弹出层 -->
		<van-popup v-model:show="show" position="bottom" :style="{ height: '40%' }" >
		<van-picker title="请选择人员" :columns="columns" @confirm="onConfirm" @cancel="onCancel" @change="onChange" />
		</van-popup>

	</div>
</template>

<script>
	import {
		ref
	} from 'vue';
	import {
		Toast
	} from 'vant';
	
	export default {		
		name: 'addComponent',
		setup() {
			const checked = ref('1');

			const columns = ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华'];
			let users = ref('请选择人员');
			const onConfirm = (value, index) => {
				Toast(`当前值: ${value}, 当前索引: ${index}`);
				getshow();
				users.value = value;
			};
			const onChange = (value, index) => {
				Toast(`当前值: ${value}, 当前索引: ${index}`);
			};
			let show = ref(false);
			const onCancel = () => {
				getshow();
			};
			function getshow() {
				if(show.value) {
					show.value = false;
				} else {
					show.value = true;
				}
			}

			return {
				checked,
				columns,
				onChange,
				onCancel,
				onConfirm,
				getshow,
				show,
				users,
			};
		},	

	}
</script>

<style scoped>
	.ap {
		width: 100%;

	}
	::v-deep .van-cell-group {
		width: 80%;
		margin: 4rem auto 2rem;
		/* border: 1px solid red; */
	}
	::v-deep .van-field {
		font-size: 1.7rem;
	}
	::v-deep .cl_ddit {
		width: 7rem;
		font-weight: 900;
	}
	/* 权限 */
	.cl_rusern {
		display: flex;
		width: 80%;
		margin: 1rem auto;
		/* border: 1px solid red; */
	}
	.cl_quxi {
		font-weight: 600;
		font-size: 1.7rem;
		margin-left: 1.5rem;
	}
	::v-deep .van-radio-group {
		font-size: 1.7rem;
		margin-left: 1rem;
	}
	::v-deep .van-button {
		margin: 4rem auto 0;
		width: 70%;
		left: 50%;
		transform: translate(-50%);
	}
</style>
