<template>
  <div class="sap">
    <!-- 首页导航栏:s -->
    <!-- left-arrow -->
<!--    <van-nav-bar title="首页" class="cl_leftarrow" />-->
    <!-- 首页导航栏:n -->

      <van-tabs v-model:active="active" class="selectTab cl_van_tabs" animated="true" swipeable="true">
        <van-tab title="1" v-if="audit">
          <template #title>
            <div class="cl_vant_icon">
<!--              <van-icon name="manager" :badge="aubit" size="2rem" /><span-->
<!--                >待审核</span-->
<!--              >-->
              <van-badge :content="aubit" max="99">
                <img src="../../assets/tr6.png" alt="" v-show="active == 0">
                <img src="../../assets/tr6s.png" alt="" v-show="active != 0">
              </van-badge>
              <p class="cl-tab_title">待审核</p>
            </div>
          </template>
          <manexamine class="cl_manexamine" :data="data" @onRefresh="refresh"></manexamine>
        </van-tab>

        <!-- 待探视:s -->
        <van-tab title="1">
          <template #title>
            <div class="cl_vant_icon">
<!--              <van-icon name="friends" :badge="visitL" /><span>待探视</span>-->
              <van-badge :content="visitL" max="99">
                <img src="../../assets/tr5.png" alt="" v-show="active == 1">
                <img src="../../assets/tr5s.png" alt="" v-show="active != 1">
              </van-badge>
              <p class="cl-tab_title">待探视</p>
            </div>
          </template>
          <manvisit class="cl_manexamine" :flag="xflag" @refreshVist="refresh"></manvisit>

        </van-tab>
        <!-- 待探视:n -->

        <!-- 待叫号:s -->
        <van-tab title="1">
          <template #title>
            <div class="cl_vant_icon">
<!--              <van-icon name="todo-list" :badge="call" /><span>待叫号</span>-->
              <van-badge :content="call" max="99">
                <img src="../../assets/tr4.png" alt="" v-show="active == 2">
                <img src="../../assets/tr4s.png" alt="" v-show="active != 2">
              </van-badge>
              <p class="cl-tab_title">待叫号</p>
            </div>
          </template>
          <mancall class="cl_manexamine" @passabiut="refresh" @passtat="passtat"></mancall>
        </van-tab>
        <!-- 待叫号:n -->
      </van-tabs>

  </div>
</template>

<script>
import manexamineComponent from "./components/manexamine.vue";
import manvisitComponent from "./components/manvisit.vue";
import mancallComponent from "./components/mancall.vue";
import { getCount } from "@/api/aubot";
import { audit } from "@/api/appdetails/appdetails.js";
// import { ref } from "vue";
export default {
  name: "aboutComponent",
  components: {
    manexamine: manexamineComponent,
    manvisit: manvisitComponent,
    mancall: mancallComponent,
  },
  data() {
    return {
      active: 0, //控制面包屑
      flag: false,
      aubit: 0, //待审核
      call: 0, //待叫号
      visitL: 0, //带探视
      count: 0,
      data: [],
      audit: false,   //审核权限
      xflag: 'false'
    };
  },
  created() {
    this.addtion();
    this.getCount();
    console.log(this.$route.query.name);
    if (this.$route.query.name - 1 == "") {
      this.active = 0;
    } else {
      this.active = this.$route.query.name - 1;
    }
    this.audit = localStorage.getItem('audit');
  },
  beforeMount() {
    var status = localStorage.getItem("status");
    if (status == 1) {
      this.flag = true;
    } else if (status == 2) {
      this.flag = false;
    }
  },
  methods: {
    async getCount() {
      const res = await getCount();
      const state = res.data.data;
      this.aubit = state.audit;
      this.call = state.call;
      this.visitL = state.visit;
    },
    // 待审核首页数据
    async addtion() {
      const res = await audit();
      let dataList = res.data.data;
      dataList.forEach((item) => {
        if (
          item.appointVisitTime == null ||
          item.appointStartTime == null ||
          item.appointEndTime == null
        ) {
          item.appointVisitTime = "";
          item.appointStartTime = "";
          item.appointEndTime = "";
        }
      });
      this.data = dataList;
    },
    // 下拉刷新徽标
    refresh(val) {
      if (val) {
        this.addtion();
        this.getCount();
      }
    },
    passtat(val) {
      this.active = val;
      this.xflag = true;
    },
  },
};
</script>

<style scoped>
.sap {
  width: 100%;
  height: 100%;
  /*border: 1px solid red;*/
}

/*.cl_leftarrow {*/
/*  width: 100%;*/
/*  height: 5rem;*/
/*  position: fixed;*/
/*  top: 0;*/
/*  left: 0;*/
/*  z-index: 999;*/
/*  !* margin: 1rem auto; *!*/
/*  font-size: 3rem;*/
/*  background-color: #00bfff;*/
/*}*/

.cl_van_tabs {
  margin-top: 0rem;
  width: 100%;
  height: 100%;
  /*border: 1px solid red;*/
}
/deep/ .van-tabs {
  width: 100%;
  height: 100%;
  /*border: 1px solid #00ff15;*/
}
/deep/ .van-tab {
  width: 100%;
  height: 100%;
  color: #757575;
}
/deep/ .van-tab--active {
  color: #171717;
}
/deep/ .van-tabs__nav .van-tabs__line {
  width: 10rem;
  left: 0rem;
  background-color: #3D53F5;
}

.selectTab /deep/ .van-tabs--line {
  height: 8rem;
}

.selectTab /deep/ .van-tabs__wrap {
  height: 8rem;
}
/*标题*/
.cl_vant_icon {
  padding: 2rem 0 2rem 0;
  height: 5rem;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.cl_vant_icon img {
  width: 2.4rem;
  height: 2.4rem;
}
.cl_vant_icon .cl-tab_title {
  font-size: 1.4rem;
  color: #171717;
  margin-top: 1rem;
}
.cl_vant_icon span {
  margin-left: 0.5rem;
}

::v-deep .van-tabs__line {
  left: 1.2rem;
  width: 4.5rem;
}

::v-deep .van-nav-bar__title {
  margin-top: 1rem;
  font-size: 1.6rem;

}
.cl_manexamine {
  height: 90vh;
  overflow-y: auto;
  /*border: 1px solid #0069ef;*/
 }
.van-divider {
  margin: 4rem 0 15rem 0;
  /*padding-bottom: 10rem;*/
}
</style>
