<template>
	<div class="ap">
			<van-search v-model="value" placeholder="请输入搜索关键词" class="cl_search" />
			<div class="cl_plus" @click="getadd(val)">＋</div>
	</div>
</template>

<script>
	import { ref } from 'vue';
	
	export default {
		name: 'searchComponent',
		props: {
			val: {
				required: true,
				type: Number,
			}
		},
		setup() {
			const value = ref('');
			return { value };
		},
		methods: {
			getadd(val) {
				if (val == 1) {
					this.$router.push({path: '/adduser'});
				} else if (val == 2) {
					this.$router.push({path: '/addto'});
				}
			}
		}
	};
</script>

<style scoped>
	.ap {
		width: 95%;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.cl_search {
		width: 80%;
	}
	.cl_plus {
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		border: 1px solid black;
		text-align: center;
		line-height: 3rem;
		font-size: 3rem;
		font-weight: 400;
		margin-left: 1rem;
	}
</style>
