<template>
  <div class="class_tabBar" v-if="$route.meta.showTabBar">
    <router-link to="/about"
      ><div><i class="iconfont">&#xe751;</i></div>
      <div>首页</div></router-link
    >
    <router-link to="/news"
      ><div><i class="iconfont">&#xe66c;</i></div>
      <div>消息</div></router-link
    >
    <router-link to="/home"
      ><div><i class="iconfont">&#xe8a0;</i></div>
      <div>我的</div></router-link
    >
  </div>
  <router-view></router-view>
</template>

<script>
// import { onMounted } from 'vue'
export default {
  name: "App",
  components: {},
  // beforeCreate(){
  //  alert('aaa')
  // }
  mounted() {
    if (window.performance.navigation.type != 1) {
        this.$router.push({path: '/login'});
      }
  },
};
</script>

<style>
@import url("static/iconfont/iconfont.css");
/* #app { */
/* font-family: Avenir, Hel */ /* vetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
/* text-align: center; */
/* } */
.iconfont {
  font-size: 2.2rem !important;
}
/* .iconfont-s {
  font-size: 2.3rem !important;
} */
a {
  text-decoration: none;
}

.router-link-active {
  color: #3D53F5;
}
a.router-link-active {
  text-decoration: none;
}
.class_tabBar {
  width: 100%;
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  /* height: 5rem; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 1.3rem;
  background-color: white;
  text-align: center;
  padding: 0.3rem 0;
  border-top: 1px solid rgb(179, 170, 170);
}
.class_tabBar div {
  margin: 0 auto;
}
</style>
