import aboutComponent from '../components/about/index.vue'
import newsComponent from '../components/news/index.vue'
import homeComponent from '../components/home/index.vue'
import loginComponent from '../components/login/index.vue'
import appdetailsComponent from '../components/appdetails/index.vue'
import visitComponent from '../components/tovisit/index.vue'
import playbackComponent from '../components/playback/index.vue'
import taskdetailsComponent from '../components/taskdetails/index.vue'
import mytaskComponent from '../components/mytask/index.vue';
import managementComponent from '../components/management/index.vue';
import addtoComponent from '../components/addto/index.vue';
import adduserComponent from '../components/adduser/index.vue';
import mancall from '../components/about/components/mancall'
import yuTobal from '../components/yuTobal/index.vue'
import previewComponent from '../components/preview/index.vue'

// import VueRouter from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
// import Vue from 'vue'
// Vue.use(VueRouter);

const routes = [
    { path: '/', redirect: '/about' },
    {
        path: '/about',
        component: aboutComponent,
        meta: {
            showTabBar: true,
        }
    },
    {
        path: '/about/mancall',
        component: mancall,
        meta: {
            showTabBar: true,
        }
    },
    {
        path: '/news',
        component: newsComponent,
        meta: {
            showTabBar: true,
        }
    },
    {
        path: '/home',
        component: homeComponent,
        meta: {
            showTabBar: true,
        }
    },
    {
        path: '/login',
        component: loginComponent,
        meta: {
            showTabBar: false,
        }
    },
    {
        path: '/appdetails',
        component: appdetailsComponent,
        meta: {
            showTabBar: false,
        }
    },
    {
        path: '/yuTobal',
        component: yuTobal,
        meta: {
            showTabBar: false,
        }
    },
    {
        path: '/tovisit',
        component: visitComponent,
        meta: {
            showTabBar: false,
        }
    },
    {
        path: '/playback',
        component: playbackComponent,
        meta: {
            showTabBar: false,
        }
    },
    {
        path: '/taskdetails',
        component: taskdetailsComponent,
        meta: {
            showTabBar: false,
        }
    },
    {
        path: '/mytask',
        component: mytaskComponent,
        meta: {
            showTabBar: false,
        }
    },
    {
        path: '/management',
        component: managementComponent,
        meta: {
            showTabBar: false,
        }
    },
    {
        path: '/addto',
        component: addtoComponent,
        meta: {
            showTabBar: false,
        }
    },
    {
        path: '/adduser',
        component: adduserComponent,
        meta: {
            showTabBar: false,
        }
    },
    {
        path: '/preview',
        component: previewComponent,
        meta: {
            showTabBar: false,
        }
    },
];
const routerHistory = createWebHistory()
const router = createRouter({
    history: routerHistory,
    routes
})
router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token')
    if (to.path == '/about') {
        if (!token) {
            return next('/login')
        } else {
            next()
        }
    } else {
        next()
    }
})
export default router