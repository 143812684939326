<template>
  <div class="ap">
    <div class="logo_img"><img src="../../../../public/logo.png" alt="" /></div>
    <div class="logo_name">登录</div>
  </div>
</template>

<script>
export default {
  name: "logoComponent",
  data() {},
  components: {},
};
</script>

<style scoped>
.ap {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  margin-top: 5rem;
}
.logo_img img {
  width: 10rem;
}
.logo_name {
  font-size: 3rem;
  text-align: center;
  /* margin-top: 1rem; */
}
</style>
