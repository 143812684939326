<template>
  <div class="ap">
    <van-overlay
      :show="flag"
      @click="show = false"
      :z-index="1999"
      :duration="0.2"
    >
      <div class="wrapper" @click.stop>
        <div class="block">
          <p>驳回原因</p>
          <van-cell-group inset style="width: 90%; margin-top: -3rem">
            <van-field
              v-model="appoIntions"
              rows="4"
              autosize
              type="textarea"
              maxlength="99"
              placeholder="请输入留言"
              show-word-limit
              style="border: 1px solid black; border-radius: 10px"
            />
          </van-cell-group>
          <div class="cl_quire">
            <van-button
              type="primary"
              class="cl_button_primary"
              @click="getcancel"
              >取消</van-button
            >
            <van-button
              type="primary"
              class="cl_button_primary"
              @click="affirmReason"
              >确认</van-button
            >
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { application } from "@/api/appdetails/appdetails.js";
export default {
  name: "reasonComponent",
  props: {
    flag: {
      required: true,
    },
  },
  data() {
    return {
      appoIntions: "",
    };
  },
  // 页面一进来就要执行的操作
  created() {},
  methods: {
    getcancel() {
      var status = true;
      if (this.flag) {
        status = false;
      } else {
        status = true;
      }
      this.$emit("flag", status);
    },

    //   点击确认的操作
    async affirmReason() {
      // console.log(this.appoIntions);
      if (this.appoIntions.length < 0) {
        this.$toast("驳回原因不能为空");
      }
      const res = await application({
        appointId: this.$route.query.id,
        appointReject: this.appoIntion,
      });
      if (res.status == 200) {
        this.$toast("驳回成功");
        this.$emit("query");
        setTimeout(()=>{
          this.$router.push('/about')
        },2000)
      } else {
        this.$toast("确认失败");
      }
    },
  },
};
</script>

<style scoped>
.ap {
  width: 100%;
}

/* 遮罩层 */
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.wrapper p {
  font-size: 2rem;
  margin-top: -0.5rem;
}

.block {
  width: 70%;
  height: 35%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 1.1rem;
}

.cl_quire {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cl_quire .cl_button_primary {
  width: 40%;
  border-radius: 0.5rem;
}

.cl_quire .cl_button_primary:first-child {
  background-color: #cccccc;
  border: none;
}
</style>
