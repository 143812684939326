<template>
    <div class="ap">
      <titleComponent :title="title"></titleComponent>
      <div class="cl_asdf">
        <van-cell-group inset>
          <van-field label="预约人" :model-value="data.userName" readonly colon="true" class="cl_data_field" />
          <van-field label="电话" :model-value="data.userAccount" readonly colon="true" class="cl_data_field" />
          <van-field label="床号" :model-value="data.userArea" readonly colon="true" class="cl_data_field" />
          <van-field label="病案号" :model-value="data.userBed" readonly colon="true" class="cl_data_field" />
          <van-field label="预约时间" :model-value="data.appointVisitTime" readonly colon="true" class="cl_data_field" />
        </van-cell-group>
      </div>
      <van-button type="primary" round class="cl_button" @click="getTask(data.appointId)">接收任务</van-button>
    </div>
</template>

<script>
import titleComponent from '../title.vue'
import { receive, subscribe } from "@/api/aubot";
export default {
    name: "detailsComponent",
     components:{
       titleComponent
     },
    data() {
        return {
          title: '预约详情',
            data: {},
            id: "",
        };
    },
    setup() {
        // 返回上一级
        const onClickLeft = () => history.back();
        return {
            onClickLeft,
        };
    },
    created() {
        this.id = this.$route.query.val;
        this.getToable();
    },
    methods: {
        // 接收任务
        async getTask(id) {
        const res = await receive(id);
        if (res.data.code == 200) {
            this.$toast("接收任务成功");
            setTimeout(() => {
            this.$router.push({ path: "/about", query: { name: "3" } });
            }, 1000);
        } else {
            this.$toast("接收任务失败");
        }
        },

        // 详情列表数据
        async getToable() {
            const res = await subscribe(this.id);
            this.data = res.data.data
            console.log(this.data);
        },
    },
    mounted() {
        this.$emit("value", this.data.id);
    },
};
</script>

<style scoped>
.ap {
    width: 100%;
    height: 100%;
    border: 1px solid #f5f5f7;
}
.cl_asdf {
  width: 100%;
  margin: 6rem auto 0;
}
.cl_data_field {
    /* border-bottom: 1px solid black; */
    font-size: 1.7rem;
    font-weight: 400;
    height: 6rem;
}

::v-deep .van-nav-bar__title {
    color: #fff;
}

.cl_button {
    width: 90%;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10rem;
    border-radius: 20px;
}

::v-deep .van-icon-arrow-left:before {
    color: #fff;
}
</style>
