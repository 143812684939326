<template>
  <div class="ap">
    <p>待探视</p>

    <!--待探视的详细内容:s  -->
    <div class="cl_task" v-for="item in data" :key="item.appointId" @click="getvisit(item.appointId)">
      <div class="cl_visitime">
        <div>
          <span><i class="iconfont">&#xe687;</i></span><span>已结束</span>
        </div>
        <div class="cl_time">
          <van-count-down :time="item.appointSubmitTime" format="DD 天 HH 时 mm 分 ss 秒" />
        </div>
      </div>
      <van-cell-group inset class="cl_max_group">
        <van-field label="预约人" :model-value="item.userName" readonly class="cl_group" />
        <van-field label="电话" :model-value="item.userAccount" readonly class="cl_group" />
        <van-field label="探视时间" :model-value="
          item.appointVisitTime +
          ' ' +
          item.appointStartTime +
          '-' +
          item.appointEndTime
        " readonly class="cl_group" />
      </van-cell-group>
      <div class="weiTanshi">未探视</div>
    </div>
    <!--待探视的详细内容:n -->
  </div>
</template>

<script>
import { visit } from "@/api/appdetails/appdetails.js";
export default {
  name: "manvisitComponent",
  data() {
    return {
      data: [],
      ts: "",
    };
  },
  created() {
    // this.ts = Date.now(); //当前的时间戳
    this.manvisit();
  },
  methods: {
    // 点击带探视的跳转
    getvisit(val) {
      this.$router.push({ path: "/tovisit", query: { id: val } });
    },
    // 待探视首页的数据接口
    async manvisit() {
      const res = await visit();
      this.data = res.data.data;
      var times = new Date().getTime(); //当前的时间戳
      this.data.forEach((item) => {
        this.ts = item.appointSubmitTime;
      });
      var time = Date.parse(this.ts); //把后台返回的时间转化为时间戳的形式
      var mise = times - time; //相减
      this.data.forEach((item) => {
        item.appointSubmitTime = mise
      });
    },
  },
};
</script>

<style scoped>
.ap {
  width: 95%;
  margin: 0 auto;
}

.ap>p {
  font-size: 2rem;
  margin-left: 1rem;
  font-weight: 600;
}

.cl_task {
  width: 92%;
  margin: 3rem auto 0;
  background-color: whitesmoke;
  border-radius: 1rem;
  padding-top: 1rem;
  padding-bottom: 0.9rem;
  margin-bottom: 3rem;
  position: relative;
  box-shadow: 0.1rem 0.1rem 1rem 0.1rem #d1caca;
}

.weiTanshi {
  position: absolute;
  top: 41%;
  left: 79%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: #968cea;
}

.cl_task:last-child {
  margin-bottom: 7.2rem;
}

.cl_visitime {
  width: 93%;
  margin: 1rem auto 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  font-size: 1.55rem;
  font-weight: 500;
  margin-bottom: 0.4rem;
}

.cl_visitime span:nth-child(2) {
  margin-left: 0.5rem;
}

.cl_time {
  color: aqua;
}

::v-deep .van-count-down {
  color: rgb(10, 232, 232);
  font-size: 1.5rem;
}

.cl_group {
  background-color: whitesmoke;
  height: 3rem;
  font-size: 1.5rem;
}

.cl_sign {
  position: absolute;
  top: 15.5rem;
  right: 8.3rem;
}

.cl_sign i {
  font-size: 3rem !important;
}

.cl_sign .iconfonts {
  font-size: 1.8rem !important;
}

.iconfont {
  font-size: 1.5rem !important;
}

::v-deep .van-cell-group--inset {
  margin: 0;
}

::v-deep .van-field__label {
  margin-right: 0;
}

::v-deep .van-cell {
  padding: 0.5rem 1rem;
}
</style>
