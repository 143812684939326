<template>

	<!-- 用户头像:s -->
	<div class="ap">
		<div class="cl_image"><img :src="data.image" alt=""></div>
		<div class="cl_information">
			<p>科室：{{ data.department }}</p>
			<p>职称：{{ data.position }}</p>
		</div>
	</div>
	<!-- 用户头像:n -->

</template>

<script>
	import { geinviw } from '@/api/aubot'
export default {
	name: 'centerComponent',
	data() {
		return {
			data: {
				department: '护理',
				position: '',
				image: 'https://img1.baidu.com/it/u=432872334,1664627058&fm=253&fmt=auto&app=138&f=JPG?w=500&h=431',
			}
		}
	},
	created() {
		this.getadmin();
	},
	methods: {
		getadmin() {
			geinviw().then(res => {
				console.log(res);
				if (res.data.code == 200) {
					if (res.data.data.staffPosition == '0') {
						this.data.position = '护士';
					} else if (res.data.data.staffPosition == '1') {
						this.data.position = '护士长';
					}
				}
			})
		}
	}
}
</script>

<style scoped>
.ap {
	width: 90%;
	margin: 2rem auto 0;
	display: flex;
	height: 12rem;
	/* margin-top: 1.5rem; */
	background-color: white;
	border-radius: 50px;
	/* margin-top: -10rem; */
}

.cl_image {
	width: 8rem;
	margin-top: 3rem;
	margin-left: 2rem;
}

.cl_image img {
	width: 7rem;
	height: 7rem;
	border-radius: 0.5rem;
}

.cl_information {
	color: black;
	font-size: 2rem;
	margin-left: 1rem;
	margin-top: 3rem;
	font-weight: 500;
}

.cl_information p {
	margin: 0%;
	margin-bottom: 1rem;
}
</style>
