<template>
	<div class="ap">
		<van-nav-bar :title="title" left-arrow @click-left="onClickLeft" class="cl_leftarrow" />
		<search :val="id"></search>
		<user :data="data" :val="id"></user>
	</div>
</template>

<script>
	import searchComponent from './components/search.vue'
	import userComponent from './components/user.vue'
	
	export default {
		name: 'managementComponent',
		setup() {
			// 返回上一级
			const onClickLeft = () => history.back();
			return {
			onClickLeft,
			};
		},
		components: {
			search: searchComponent,
			user: userComponent,
		},
		data() {
			return {
				title: '',
				id: -1,
				data: [
					{
						id: 1,
						name: '张三',
						phone: '12345789658',
					},
					{
						id: 2,
						name: '李四',
						phone: '12345789658',
					},
					{
						id: 3,
						name: '王五',
						phone: '12345789658',
					},
					{
						id: 4,
						name: '赵六',
						phone: '12345789658',
					},
				]
			}
		},
		beforeMount() {
			const id = this.$route.query.id;
			this.id = id;
			if (id == 1) {
				this.title = '用户管理';
			} else if (id == 2) {
				this.title = '权限管理';
			}
		}
	}
</script>

<style scoped>
	.ap {
		width: 100%;
	}
	.cl_leftarrow {
		width: 100%;
		height: 5rem;
		font-size: 3rem;
		background-color: skyblue;
	}
</style>