import request from "@/utils/request";

// 首页数据统计的接口
export function getCount() {
    return request({
        url: "/care/view/count",
        method: "post",
    });
}
// 带探视探视对象的接口
export function appOint(id) {
    return request({
        url: "care/view/appoint/" + id,
        method: "post",
    });
}

export function subscribe(id) {
    return request({
        url: "care/view/appoint/" + id,
        method: "post",
    });
}

// 待叫号的接口
export function receive(id) {
    return request({
        url: "care/handle/receive/" + id,
        method: "post",
    });
}

// 用户信息
export function geinviw() {
    return request({
        url: "care/view/info",
        method: "post",
    });
}

// 用户头像
export function getavatar() {
    return request({
        url: 'client/user/avatar',
        method: 'post',
    })
}
