import request from "@/utils/request";

export function login(data) {
  return request({
    url: "client/user/login",
    method: "post",
    data,
  });
}

// 我的登录接口
export function logins(data) {
  return request({
    url: "care/handle/login",
    method: "post",
    data,
  });
}
