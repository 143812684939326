<template>
  <!-- 预约详情页:s -->
  <div class="sap">
    <van-cell-group inset>
      <van-field
        label="预约人"
        :model-value="dataList.userName"
        readonly
        class="cl_data_field"
      />
      <van-field
        label="电话"
        :model-value="dataList.userAccount"
        readonly
        class="cl_data_field"
      />
      <van-field
        label="床号"
        :model-value="dataList.userArea"
        readonly
        class="cl_data_field"
      />
      <van-field
        label="病案号"
        :model-value="dataList.userBed"
        readonly
        class="cl_data_field"
      />
      <van-field
        label="预约时间"
        :model-value="dataList.appointVisitTime"
        readonly
        class="cl_data_field"
      />
    </van-cell-group>
  </div>
  <!-- 预约详情页:n -->
</template>

<script>
import { details } from "@/api/appdetails/appdetails.js";
export default {
  props: {
    val: String,
  },
  name: "detailsComponent",
  data() {
    return {
      dataList: {},
      value: "",
    };
  },
  created() {
    this.value = this.$route.query.id;
    this.detailsi();
  },
  methods: {
    // 预约详情页面的数据
    async detailsi() {
      const res = await details(this.value);
      this.dataList = res.data.data;
    },
  },
  mounted() {
    // this.$emit("value", this.data.id);
  },
};
</script>

<style scoped>
.sap {
  width: 100%;
  height: 90%;
  margin: 6rem auto 0;
}

.cl_data_field {
  font-size: 1.7rem;
  font-weight: 400;
  height: 6rem;
}
</style>
