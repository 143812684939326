<template>
	<div class="app">
		<centerComponent></centerComponent>
		<subscribeComponent></subscribeComponent>
		<!-- <userdingComponent></userdingComponent> -->
		<!-- <bindingComponent v-if="flag"></bindingComponent> -->
	</div>
</template>

<script>
import centerComponent from './components/center.vue'
import subscribeComponent from './components/subscribe.vue'
// import userdingComponent from './components/userding.vue'
// import bindingComponent from './components/binding.vue'
export default {
	name: 'homeComponents',
	components: {
		centerComponent,
		subscribeComponent,
		// userdingComponent,
		// bindingComponent,
	},
	// data() {
	// 	return {
	// 		flag: false,
	// 	}
	// },
	// beforeMount() {
	// 	var status = localStorage.getItem('status');
	// 	if (status == 1) {
	// 		this.flag = true;
	// 	} else if (status == 2) {
	// 		this.flag = false;
	// 	}
	// }
}


</script>

<style scoped>
.app {
	width: 100%;
  height: 100%;
}

::v-deep .van-nav-bar__title {
	color: #fff;
	line-height: 62px;
}
</style>
