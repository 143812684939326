<template>
	<div class="asp">
    <titleComponent :title="title"></titleComponent>
		<relsourComponent :val="val"></relsourComponent>
	</div>
</template>

<script>
import titleComponent from '../title.vue'
import relsourComponent from './components/visit.vue'
	export default {
		name: 'visitComponent',
		components: {
			relsourComponent,
      titleComponent
		},
		data() {
			return {
        title: '待探视',
				val: -1,
			}
		},
		setup() {
			// 返回上一级
			const onClickLeft = () => history.back();
			return {
			onClickLeft,
			};
		},
		mounted() {
			this.val = this.$route.query.id;
		}
	}

</script>

<style scoped>
	.asp {
		width: 100%;
    height: 100%;
    border: 1px solid #f5f5f7;
	}
</style>
