<template>
  <div class="showVideo">
    <video class="fullScreen" ref="fullScreen" autoPlay playsInline></video>
    <van-button v-if="end" class="start-visit" size="mini" round type="primary" @click="initWebrtc()">开始录制</van-button>
    <van-button v-else class="end-visit" size="mini" round type="info" @click="endRtc()">结束录制</van-button>
  </div>
</template>

<script>
import 'aliyun-webrtc-sdk'
import {getRtcAuth, startRecord, stopRecord} from "@/api/rtc";
import {Toast} from "vant";

const {AliRtcEngine} = window;
const aliWebrtc = new AliRtcEngine();

export default {
  name: "RtcRecord",
  props: {
    appointmentId: {
      require: true,
      type: String
    }
  },
  data() {
    return {
      recording: false,
      taskId: '',
      end: true
    }
  },
  methods: {
    initWebrtc() {
      // 手机端 facingMode指定user时启用前摄像头、指定environment时启用后置摄像头
      aliWebrtc.currentCamera = {facingMode: "environment"}
      aliWebrtc.enableHighDefinitionPreview(false);
      aliWebrtc.setVideoProfile({
        width: 640 * 4,
        height: 480 * 4,
      }, 1);

      aliWebrtc.isSupport().then(() => {
        this.joinRoom()
      }).catch(err => {
        alert('不支持rtc!')
        console.log('err: ', err)
      })
    },

    joinRoom() {
      const video = this.$refs.fullScreen
      aliWebrtc.startPreview(video).then(() => {
        console.log("开启预览成功")
        this.rtcAuth()
      }).catch(err => {
        console.log('authErr： ', err)
        alert("开启预览失败")
      })
    },

    rtcAuth() { // 鉴权 获取基本信息
      console.log("获取鉴权信息..")
      getRtcAuth(this.appointmentId).then(res => {
        const {code, data} = res.data;
        if (code !== 200) {
          alert('鉴权失败!')
          return;
        }
        console.log('鉴权成功!')

        console.log('res.userId: ', data.userId)
        const joinData = {
          userid: data.userId,
          channel: this.appointmentId,
          appid: data.appId,
          timestamp: data.timestamp,
          nonce: data.nonce,
          token: data.token,
          gslb: [data.gslb]
        }

        aliWebrtc.joinChannel(joinData).then(() => {
          aliWebrtc.publish().then(() => {
            aliWebrtc.configLocalAudioPublish = true;
            aliWebrtc.configLocalCameraPublish = true;
            aliWebrtc.configLocalScreenPublish = true;
            console.log('推流成功!')
            this.end = false;

            // 启用录制功能
            startRecord(this.appointmentId).then((res) => {
              console.log("startRecord res", res);
              const {code, data} = res.data;
              if (code !== 200) {
                alert('鉴权失败!')
                return;
              }
              this.taskId = data;
              this.recording = true;
            }).catch((e) => {
              console.log("startRecord err", e);
            })

          }).catch(() => {
            alert('推流失败!')
          })
        }).catch(() => {
          alert('加入频道失败')
        })
      }).catch((e) => {
        alert("获取鉴权信息失败")
        console.log(e)
      })
    },

    endRtc() {
      console.log('endRtc', this.recording, this.taskId)
      // 停止录制
      if (this.recording && this.taskId) {
        stopRecord(this.taskId).then((res) => {
          console.log("stopRecord res", res);
          this.recording = false;
        }).catch((e) => {
          console.log("stopRecord err", e);
        })
      }

      aliWebrtc.leaveChannel()
      aliWebrtc.unPublish()
      aliWebrtc.dispose();
      aliWebrtc.stopPreview()
      this.end = true
      this.$emit('end')

      Toast.success('录制完成,正在上传！')
      setTimeout(() => {
        this.$emit('generate');
      }, 2000);
    },
  }
}
</script>

<style scoped>
.showVideo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 990;
  background: #F1F2F4;
  display: flex;
  justify-content: center;
}

.fullScreen {
  height: 100%;
}

.end-visit {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 4rem;
  bottom: 100px;
  z-index: 991;
}

.start-visit {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 4rem;
  bottom: 100px;
  z-index: 991;
}
</style>
