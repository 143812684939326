<template>
  <div class="aps">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
<!--      <p>待叫号</p>-->
      <!-- 待叫号的详细内容:s -->
      <div class="cl_task" v-for="item in data" :key="item.appointId" @click.stop="getvisit(item.appointId)">
        <div class="cl_visitime">
          <div class="cl_notin">
            <span><img src="../../../assets/tr6.png" alt="" style="width: 1.8rem;height: 1.8rem"></span><span>待叫号</span>
          </div>
          <div class="cl_time"><van-count-down :time="item.unixTime" format="DD 天 HH 时 mm 分 ss 秒" />
          </div>
        </div>
        <van-cell-group inset class="cl_max_group">
          <van-field label="预约人" :model-value="item.userName" readonly class="cl_group" />
          <van-field label="电话" :model-value="item.userAccount" readonly class="cl_group" />
          <van-field label="探视时间" :model-value="item.appointTime" readonly class="cl_group" />
        </van-cell-group>
        <div class="cl_button-xls">
          <van-button type="primary" class="cl_button" @click.stop="challenge(item.appointId,item.appointCall)" v-if="item.appointCall == true">
            叫号</van-button>
          <van-button style="width: 100%" type="danger" class="cl_button-item" v-else @click.stop="challenge(item.appointId,item.appointCall)">
            叫号</van-button>
        </div>
      </div>
      <!-- 待叫号的详细内容:n -->
      <van-divider>没有更多啦</van-divider>
    </van-pull-refresh>
  </div>
</template>

<script>
  // import { timens } from "@/time/times.js";
  import { call, handles } from "@/api/appdetails/appdetails.js";
import { Toast } from "vant";
  export default {
    name: "mancallComponent",
    data() {
      return {
        data: [],
        callId: "",
        isLoading: false,
      };
    },
    created() {
      this.callList();
    },
    methods: {
      // 叫号首页请求
      async callList() {
        const res = await call();
        // console.log(res);
        this.data = res.data.data;
        var nowTime = new Date().getTime(); //当前的时间戳
        this.data.forEach((item) => {
          item.appointTime = item.appointVisitTime + ' ' + item.appointStartTime;
          const startDateTime = Date.parse(`${item.appointVisitTime} ${item.appointStartTime}`)
          item.unixTime = startDateTime > nowTime ? startDateTime - nowTime : 0;  //剩余时间戳
        });
      },

      // 点击叫号/患者端未签到
      async challenge(id,status) {
        if (status) {
          const res = await handles(id);
          console.log(res);
          if (res.data.code == 200) {
            Toast.success('叫号成功');
            this.callList();
            this.$emit('passabiut',true);
            setTimeout(() => {
              this.$emit('passtat',1);
            }, 1000);
          } else {
            Toast.fail('叫号失败');
          }
        } else {
          Toast.fail('用户未签到');
        }

      },

      // 下拉刷新会触发这个事件
      onRefresh() {
        setTimeout(() => {
          this.isLoading = false;
          this.callList();
          this.$emit('passabiut',true);
        }, 1000);
      },
      // 点击待探视的跳转
      getvisit(val) {
        this.$router.push({ path: "/tovisit", query: { id: val } });
      },
    },
  };
</script>

<style scoped>
  .aps {
    width: 95%;
    min-height: 100%;
    margin: 0 auto;
    overflow: hidden;
  }
  .van-pull-refresh {
    width: 100%;
    min-height: 90%;
    overflow-y: auto;
    /*border: 1px solid red;*/
  }
  .ap p {
    font-size: 2rem;
    margin-left: 1rem;
    font-weight: 600;
  }
  .cl_task {
    width: 100%;
    margin: 2rem auto 0;
    background-color: white;
    border-radius: 1rem;
    margin-bottom: 3rem;
    position: relative;
    /*box-shadow: 0.1rem 0.1rem 1rem 0.1rem #d1caca;*/
  }

  .cl_task:last-child {
    margin-bottom: 7.2rem;
  }

  .cl_visitime {
    width: 95%;
    border-bottom: 0.5px solid #EEEEEE;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem 0;
  }
  .cl_notin {
    display: flex;
    align-items: center;
  }

  .cl_notin span:nth-child(2) {
    margin-left: 1rem;
    font-weight: 600;
    font-size: 1.4rem;
    color: #212121;
  }

  .van-count-down {
    color: rgb(55, 201, 201);
    margin-top: 0.2rem;
    margin-right: 1rem;
  }

  .iconfont {
    font-size: 1.5rem !important;
  }

  .cl_group {
    background-color: white;
    height: 3rem;
    font-size: 1.5rem;
  }
  .cl_button-xls {
    width: 95%;
    border-top: 0.5px solid #EEEEEE;
    padding: 1rem 0;
    margin: 1rem auto 0;
  }
  .cl_button {
    width: 100%;
    /* border-radius：左上圆角值 右上圆角值 右下圆角值 左下圆角值； */
    border-radius: 0 0 0.5rem 0.5rem;
    background: #3D53F5;
    border:   none;
    border-radius: 10px;
  }

  .cl_button-item {
    background: #c2c7d0;
    border: 1px solid #c2c7d0;
    border-radius: 10px;
  }

  ::v-deep .van-cell-group--inset {
    margin: 0;
  }

  ::v-deep .van-cell {
    padding: 0.5rem 1rem;
  }

  ::v-deep .van-field__label {
    margin-right: 0;
  }
</style>
