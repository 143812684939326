import request from "@/utils/request";

// 点击驳回的接口
export function application(data) {
  return request({
    url: "care/handle/reject",
    method: "post",
    data,
  });
}

// 审核接口
export function audit() {
  return request({
    url: "care/view/audit",
    method: "post",
  });
}

// 首页点击同意接口
export function agRee(id) {
  return request({
    url: "care/handle/agree/" + id,
    method: "post",
  });
}

// 预约详情点击同意接口
export function make(id) {
  return request({
    url: "care/view/appoint/" + id,
    method: "post",
  });
}

// 消息首页列表数据
export function tidings() {
  return request({
    url: "care/view/task/",
    method: "post",
  });
}

// 预约详情页面的接口
export function details(id) {
  return request({
    url: "care/view/appoint/" + id,
    method: "post",
  });
}

// 探视列表接口
export function visit() {
  return request({
    url: "/care/view/visit/",
    method: "post",
  });
}

// 待叫号接口
export function call() {
  return request({
    url: "care/view/call/",
    method: "post",
  });
}

// 点击接收任务的接口
export function receive(id) {
  return request({
    url: "care/handle/receive/" + id,
    method: "post",
  });
}


// 叫号接口
export function handles(id) {
  return request({
    url: "care/handle/call/" + id,
    method: "post",
  });
}

