<template>
	<div class="ap">
		<van-swipe-cell v-for="(v,k) in data" :key="k" class="cl_vancell">
			<div class="cl_apply_table"  @click="getEdit(val,v.id)">
				<van-cell-group inset class="cl_inset">
					<van-field label="姓名" colon="true" :model-value="v.name" readonly />
					<van-field label="账号/电话" colon="true" :model-value="v.phone" readonly />
				</van-cell-group>
				<div class="cl_exmine">
					<p v-show="val == 2">审核员</p>
					<van-icon name="arrow" class="cl_arrow" />
				</div>
			</div>
			<template #right>
				<van-button square text="删除" type="danger" class="delete-button" />
			</template>
		</van-swipe-cell>
	</div>
</template>

<script>
	import { useRouter } from 'vue-router';
	export default {
		name: 'userComponent',
		props: {
			val: {
				required: true,
				type: Number,
			},
			data: {
				required: true,
				type: Array,
			}
		},
		setup() {
			const router = useRouter();
			function getEdit(val,uid) {
				if (val == 1) {
					router.push({path: '/adduser', query: {id: uid}});
				} else if (val == 2) {
					router.push({path: '/addto', query: {id: uid}});
				}
			}
			return {
				getEdit,
			}
		},
		
	};
</script>

<style scoped>
	.ap {
		width: 95%;
		margin: 0 auto;
	}
	/* 滑动单元格 */
	.cl_vancell {
		/* border: 1px solid black; */
		margin: 1rem auto;
		background-color: PapayaWhip;
	}
		/* 删除 */
	.delete-button {
		height: 100%;
	}
	.cl_apply_table {
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	::v-deep .van-cell-group {
		width: 70%;
		font-size: 1.5rem;
	}
	::v-deep .van-field {
		background-color: PapayaWhip;
	}
	.cl_exmine {
		width: 30%;
	}
	.cl_exmine p {
		font-size: 1.5rem;
		position: absolute;
		top: -0.5rem;
		right: 2rem;
		color: 	Turquoise;
	}
	.cl_arrow {
		position: absolute;
		font-size: 3rem;
		bottom: 1.8rem;
		right: 2rem;
	}
</style>
