<template>

	<!-- 我的任务:s -->
	<div class="ap">
		<p>我的任务</p>
		<div class="cl_subscribe">
			<div @click="getBeall(1)">
				<p><img src="../../../assets/a1.png" alt=""></p>
				<p>全部</p>
			</div>
			<div @click="getBeall(2)">
				<p><img src="../../../assets/a2.png" alt=""></p>
				<p>待探视</p>
			</div>
			<div @click="getBeall(3)">
				<p><img src="../../../assets/a3.png" alt=""></p>
				<p>已结束</p>
			</div>
		</div>
		<div class="box-img" @click="getuser(1)">
			<div><img src="../../../../public/image/binding.png" alt=""></div>
			<div class="box-text">用户管理</div>
		</div>
		<div class="box-img"  @click="getuser(2)" v-if="status">
			<div><img src="../../../../public/image/binding.png" alt=""></div>
			<div class="box-text">权限管理</div>
		</div>
	</div>
	<!-- 我的任务:n -->
</template>

<script>
import { geinviw } from '@/api/aubot';

// import userding from '../components/userding.vue'
export default {
	name: 'subscribeComponent',
	data() {
		return {
			status: false,
		}
	},
	// components:{userding},
	created() {
		this.getadmin();
		console.log(this.status);
	},
	methods: {
		getBeall(val) {
			sessionStorage.setItem('id', val);
			this.$router.push({ path: '/mytask' });
		},
		// 点击用户管理的时候
		getuser(val) {
			this.$router.push({ path: '/management', query: { id: val } });
		},
		getadmin() {
			geinviw().then(res => {
				console.log(res);
				if (res.data.code == 200) {
					if (res.data.data.staffPosition == '0') {
						this.status = false;
					} else if (res.data.data.staffPosition == '1') {
						this.status = true;
					}
				}
			})
		}
	}
}
</script>

<style scoped>
@font-face {
	font-family: "iconfont logo";
	src: url('https://at.alicdn.com/t/font_985780_km7mi63cihi.eot?t=1545807318834');
	src: url('https://at.alicdn.com/t/font_985780_km7mi63cihi.eot?t=1545807318834#iefix') format('embedded-opentype'),
		url('https://at.alicdn.com/t/font_985780_km7mi63cihi.woff?t=1545807318834') format('woff'),
		url('https://at.alicdn.com/t/font_985780_km7mi63cihi.ttf?t=1545807318834') format('truetype'),
		url('https://at.alicdn.com/t/font_985780_km7mi63cihi.svg?t=1545807318834#iconfont') format('svg');
}

.iconfont {
	font-family: "iconfont" !important;
	font-size: 20px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

.ap {
	width: 90% !important;
	margin: 0 auto;
	border-radius: 50px;
	/* border: 0.02667rem solid rgb(243, 239, 239); */
	background: #fff;
}

.ap>p {
	font-size: 2rem;
	margin-left: 1rem;
	font-weight: 600;
}

.ap .box-img {
	display: flex;
	margin-left: 1rem;
	margin: 2rem 1.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid black;
}

.ap .box-img img {
	width: 2.8rem;
	height: 2.8rem;
}

.ap .box-img .box-text {
	font-size: 2rem;
	margin-left: 1rem;
}

.cl_subscribe {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 100%;
	margin: 1rem auto;
	background-color: whitesmoke;
	margin-bottom: 3rem;
}
.cl_subscribe img {
  width: 4rem;
  height: 4rem;
}
.cl_subscribe>div {
	text-align: center;
}
</style>
